import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";

import { Layout, ToggleCards, PublicationsSection } from "../components";

const Science = ({ data }) => {
  const { allContentfulPublication } = data;
  const cardArray = _.sortBy(
    _.get(data, "allContentfulSciencePageCard.nodes"),
    "order"
  );
  return (
    <Layout className="science" pageData={_.get(data, "contentfulPageData")}>
      <section className="toggle-wrapper">
        <div className="toggle-container">
          <ToggleCards
            iconClass="scienceIcon"
            data={cardArray}
            type="advanced"
          />
        </div>
      </section>
      <PublicationsSection
        data={allContentfulPublication}
        title="Publications"
      />
    </Layout>
  );
};

export default Science;

export const query = graphql`
  query scienceQuery {
    contentfulPageData(title: { eq: "Science" }) {
      title
      description
      image {
        file {
          url
        }
      }
      header
    }
    allContentfulSciencePageCard {
      nodes {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        order
        icon {
          file {
            url
          }
          title
        }
      }
    }
    allContentfulPublication {
      nodes {
        title {
          childMarkdownRemark {
            html
          }
        }
        authors {
          internal {
            content
          }
        }
        journal {
          internal {
            content
          }
        }
        url {
          internal {
            content
          }
        }
        date
      }
    }
  }
`;
